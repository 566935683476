import Swiper from 'swiper/bundle';

const swiper = new Swiper('#swiper-main', {

    centeredSlides: true,
    loop: true,
    pagination: {
        el: '#mainSlider-pag',
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
    },
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: '#mainSlider-next',
        prevEl: '#mainSlider-prev',
    },
    on: {
        init: function () {

        },
        transitionStart: function () {
            $(".swiper-slide-active").addClass("active");
            $(".swiper-slide-prev").removeClass("active");
            $(".swiper-slide-next").removeClass("active");
        },
    },
    breakpoints: {
        1024: {
            slidesPerView: 1.6,
            spaceBetween: 30
        },
        768: {
            slidesPerView: 1.6,
            spaceBetween: 30
        },
        640: {
            slidesPerView: 1,
            spaceBetween: 10
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 10
        }
    }
});

$("#msgModal").modal("show");
$("#msgModal").on('shown.bs.modal', function () {
    var swiper2 = new Swiper('#swiper-msg', {
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: true,
        },
        centeredSlides: true,
        pagination: {
            el: '#modal-pag',
            type: "fraction"
        },
        navigation: {
            nextEl: '#modal-next',
            prevEl: '#modal-prev',
        }
    });
});
